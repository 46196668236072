import { Entry } from '@belong/types';
import { IAdobeTargetActivity, IAdobeTargetExperience } from './types';

export const FAdobeTargetActivity = (data: Entry<any>): IAdobeTargetActivity => {
  const { identifier, mBoxName, activityId, experiences } = data.fields;
  return {
    identifier,
    contentType: 'adobeTargetActivity',
    mBoxName,
    activityId,
    experiences: experiences.map((experience: Entry<any>) => FAdobeTargetExperience(experience))
  };
};

export const FAdobeTargetExperience = (data: Entry<any>): IAdobeTargetExperience => {
  const { identifier, experienceName, experienceContent } = data.fields;
  return {
    identifier,
    contentType: 'adobeTargetExperience',
    experienceName,
    experienceContent
  };
};
