import { logger } from '@belong/logging';
import type { IncomingHttpHeaders } from 'http';
import { ICtxProps, IAdobeTargetOffer } from './types';
import { CONFIG } from '../target-config';
import { getVisitorId } from './getVisitorId';
import { getQAMode } from './overrides';

/**
 * This function works both in server side as well as client side. Given an
 * MBox location it makes a call to Adobe Target to determine what experience
 * should be shown. It will return the result of the Delivery API from Target.
 *
 * @param ctxProps // an object containing the context properties
 * @param mboxLocation
 * @param sessionId
 * @param supplementalDataId
 *
 */

export const getOffer = async (
  ctxProps: ICtxProps,
  mboxLocation: string,
  sessionId: string,
  supplementalDataId: string
): Promise<IAdobeTargetOffer | undefined> => {
  const { ctxProtocol, ctxHost, ctxPath, ctxHeaders, ctxCookies, ctxQuery } = ctxProps;

  logger.info(`Fetching Target Experience for ${mboxLocation} with sessionId: ${sessionId}`);
  let requestURL: string;
  let headers: IncomingHttpHeaders | Record<string, never>;
  let cookies: string;

  if (ctxHost) {
    // server
    requestURL = ctxProtocol + ctxHost + ctxPath + ctxQuery.toString();
    headers = ctxHeaders || {};
    cookies = ctxCookies ?? '';
  } else {
    // client
    requestURL = window && `${window.location.protocol}//${window.location.host}${ctxPath}`;
    headers = {};
    cookies = window.document.cookie;
  }

  const requestBody = {
    context: {
      channel: 'web',
      address: { url: `${ctxProtocol}://${requestURL}` },
      userAgent: headers['user-agent'],
      clientHints: {
        model: headers['sec-ch-ua-model'],
        mobile: headers['sec-ch-ua-mobile'] === '?1',
        platform: headers['sec-ch-ua-platform'],
        browserUAWithFullVersion: headers['sec-ch-ua']
      }
    },
    experienceCloud: {
      analytics: {
        supplementalDataId,
        logging: 'server_side',
        trackingServer: CONFIG.trackingServer,
        trackingServerSecure: CONFIG.trackingServer
      }
    },
    property: { token: CONFIG.propertyToken },
    execute: {
      mboxes: [
        {
          index: 1,
          name: mboxLocation,
          address: {
            url: requestURL
          },
          parameters: {
            ipAddress: `${headers['x-forwarded-for']}`
          }
        }
      ]
    },
    id: {
      tntId:
        sessionId /* The tntId is the primary identifier in Target for a user. You can supply this ID or Target will auto-generate it if the request doesn’t contain one. */,
      thirdPartyId:
        undefined /* Auth0ID? OctaneID? SalesforceID? The thirdPartyId is your company’s identifier for the user that you can send with every call. When a user logs in to a company’s site, the company typically creates an ID that is tied to the visitor’s account, loyalty card, membership number, or other applicable identifiers for that company. */,
      marketingCloudVisitorId: getVisitorId(cookies, CONFIG.adobeCookieName)
      /* The marketingCloudVisitorId is used to merge and share data between different Adobe solutions. The marketingCloudVisitorId is required for integrations with Adobe Analytics and Adobe Audience Manager. */
      /* Along with the Experience Cloud Visitor ID, additional customer IDs and an authenticated status for each visitor can be utilized.
      customerIds: [ {
          id: '26574588293486422770329453029630946292',
          integrationCode: 'AAM'
        }
      ] */
    },
    ...getQAMode(ctxQuery)
  };

  // Uncomment to debug the outgoing request to Adobe Target
  // logger.info(`Requesting Target Experience from: https://${CONFIG.serverDomain}/rest/v1/delivery?client=${CONFIG.client}&sessionId=${sessionId}`);

  // Uncomment to debug the client headers that can be sent to Adobe Target
  // logger.info(`Adobe Target Headers: ${JSON.stringify({headers}, null, 2)}`);

  // Uncomment to debug the request payload that will be sent to Adobe Target
  // logger.info(`Adobe Target Request Body: ${JSON.stringify({requestBody}, null, 2)}`);

  const response = await fetch(
    `https://${CONFIG.serverDomain}/rest/v1/delivery?client=${CONFIG.client}&sessionId=${sessionId}`,
    {
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
        Host: CONFIG.serverDomain,
        Connection: 'keep-alive',
        'Content-Type': 'application/json',
        'X-Forwarded-For': `${headers['x-forwarded-for']}`
      } as HeadersInit,
      body: JSON.stringify(requestBody)
    }
  );

  return response.json();
};
