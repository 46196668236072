// this little utility is used to terminate a promise if it takes too long to resolve

export const timer = (ms: number): Promise<void> => {
  return new Promise((_resolve, reject: any) =>
    setTimeout(() => reject(new Error('the function took too long to return a result')), ms)
  );
};

export const selfTerminate = async <T>(
  job: Promise<T | undefined>,
  timeLimit: number
): Promise<void | T | undefined> => {
  const result = await Promise.race([job, timer(timeLimit)]);
  return result;
};
