import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Entry, IPage } from '@belong/types';
import { parseLinkData } from '../helpers/parseLinkData';
import { FPageAlert } from './pageAlert.factory';
import { FLink } from './elements/elementLink.factory';
import { FElementEmbeddedInformationModal } from './elements/elementEmbeddedInformationModal.factory';
import { FlexibleComponentMap, parseFlexibleContent } from './parseFlexibleContent';
import { FSystemIcon } from './elements';
import { FEmail, FGenericInput, FPassword } from './inputs';
import {
  FMoleculeAsset,
  FMoleculeAlert,
  FMoleculeRichText,
  FMoleculeText,
  FMoleculeMediaCaptionBlock
} from './molecules';
import { FSectionInfo } from './sections/sectionInfo.factory';
import { FSectionPageTitle } from './sections/sectionPageTitle.factory';
import { FGenericScreen } from './genericScreen.factory';
import { FGenericInputGroup } from './inputs/genericInputGroup.factory';
import { FOrganismServiceLinks } from './organisms/organismServiceLinks.factory';
import { FOrganismSecondaryTile } from './organisms/organismSecondaryTile.factory';
import { FOrganismNbnServiceTile } from './organisms/organismNbnServiceTile.factory';

export type AnySectionData = Record<string, any>;

/**
 * `sectionParser` can have some app-specific behaviours, so we require each app
 * to implement it.
 */
export type SectionParser = (section: any) => Promise<AnySectionData>;
export type ContentPatcher = (pageContent: IPage, pageUrl: string, correlationId: string) => IPage;

export const CMS_FACTORY_MAP = new Map([
  ['elementEmbeddedInformationModal', { factory: FElementEmbeddedInformationModal }],
  ['elementLink', { factory: FLink }],
  ['elementSystemIcon', { factory: FSystemIcon }],
  ['inputEmail', { factory: FEmail }],
  ['inputGeneric', { factory: FGenericInput }],
  ['inputGenericGroup', { factory: FGenericInputGroup }],
  ['inputPassword', { factory: FPassword }],
  ['moleculeAlert', { factory: FMoleculeAlert }],
  ['moleculeAsset', { factory: FMoleculeAsset }],
  ['moleculeMediaCaptionBlock', { factory: FMoleculeMediaCaptionBlock }],
  ['moleculeRichText', { factory: FMoleculeRichText }],
  ['moleculeText', { factory: FMoleculeText }],
  ['screenGeneric', { factory: FGenericScreen }],
  ['sectionInfo', { factory: FSectionInfo }],
  ['sectionPageTitle', { factory: FSectionPageTitle }],
  ['organismNbnService', { factory: FOrganismNbnServiceTile }],
  ['organismServiceLinks', { factory: FOrganismServiceLinks }],
  ['organismSecondaryTile', { factory: FOrganismSecondaryTile }]
]);

export const FPage = async (
  pageData: any,
  sectionParser: SectionParser,
  flexibleComponentsMap: FlexibleComponentMap = CMS_FACTORY_MAP
): Promise<IPage> => {
  const data: Entry<any> = pageData[0];

  const {
    alert,
    breadcrumbs,
    content,
    description,
    enableLiveChat,
    flowName,
    journeyName,
    keywords,
    noFollow,
    noIndex,
    pageName,
    pageTitle,
    pageUrl,
    screen,
    sections
  } = data.fields;
  const url = parseLinkData(pageUrl).href;
  const result: IPage = {
    breadcrumbs: breadcrumbs?.map(FLink),
    description: documentToPlainTextString(description),
    flowName,
    identifier: url,
    journeyName,
    keywords,
    meta: {
      entryId: data.sys?.id
    },
    noFollow,
    noIndex,
    pageName,
    pageTitle,
    pageUrl: url,
    screen,
    sections: await Promise.all((sections ?? []).map(sectionParser)),
    content: parseFlexibleContent(content ?? [], flexibleComponentsMap),
    shouldHaveLiveChat: enableLiveChat ?? false
  };

  if (alert) {
    result.alert = FPageAlert(alert);
  }

  return result;
};
