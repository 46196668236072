import { Entry } from 'contentful';
import type { Document } from '@contentful/rich-text-types';
import { ITileHeader, STATUS_INDICATOR_STATUSES, THeadingVariant } from '@belong/types';

import { FRichText } from '../richText.factory';
import { FImage } from '../image.factory';

export interface IEntryTileHeader {
  key: string;
  a11yTitle?: string;
  a11yPrefixStatusIndicator?: string;
  additionalInfo?: string | Document;
  headingVariant?: THeadingVariant;
  image?: Entry<any>;
  statusIndicator?: STATUS_INDICATOR_STATUSES;
  subtitle?: string;
  title: string;
  value?: string;
}

export const FMoleculeTileHeader = (data: Entry<IEntryTileHeader>): ITileHeader => {
  const { image, additionalInfo, ...otherFields } = data.fields;

  let additionalInfoContent: ITileHeader['additionalInfo'];
  if (additionalInfo) {
    additionalInfoContent = typeof additionalInfo === 'string' ? additionalInfo : FRichText(additionalInfo);
  }

  return {
    image: image && FImage(image),
    additionalInfo: additionalInfoContent,
    ...otherFields
  };
};
