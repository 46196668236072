import { useCallback, useEffect, useState } from 'react';
import { AnalyticsEvent, AnalyticsEvents, EVENT_NAME } from '@belong/analytics';
import { makeWeakCancelable } from '@belong/utils/promise';
import { sendToAdobe, sendToGtm } from './dispatchers';
import { waitForAdobe, waitForGtm } from './checkers';

/**
 * Send any analytics events to Adobe Target and Google Tag Manager.
 *
 * If any events fire before a vendor's script has loaded, they will be
 * "caught" and held in a queue until the vendor's platform has initialised.
 * Then all early caught events will be immediately re-dispatched to the
 * vendor in their original firing order.
 */
function useConnectToAnalyticsVendors(analyticsEvents: AnalyticsEvents, shouldRun: boolean): void {
  /*
   * Stores analytics events that were fired too early for our vendor
   * platforms to have received.
   */
  const [earlyEvents, setEarlyEvents] = useState<AnalyticsEvent[]>([]);

  /**
   * Adobe and Google operate independently, so when one has loaded we mark its
   * own "has received all early events" flag without affecting the watcher/waiter
   * for the other one.
   */
  const [hasDrainedEarlyEventsToAdobe, setHasDrainedEarlyEventsToAdobe] = useState<boolean>(false);
  const [hasDrainedEarlyEventsToGtm, setHasDrainedEarlyEventsToGtm] = useState<boolean>(false);

  /*
   * Allow ourselves ot keep track of what's loaded and when
   */
  const [hasAdobeLoaded, setHasAdobeLoaded] = useState(false);
  const [hasGtmLoaded, setHasGtmLoaded] = useState(false);

  const onAdobeLoaded = useCallback((hasCanceled: boolean) => {
    if (!hasCanceled) {
      setHasAdobeLoaded(true);
    }
  }, []);

  const onGtmLoaded = useCallback((hasCanceled: boolean) => {
    if (!hasCanceled) {
      setHasGtmLoaded(true);
    }
  }, []);

  /*
   * Add a given event to the `earlyEvents` list.
   * These will be re-dispatched to the vendor platforms once they have loaded.
   * This process of "re-dispatching" is called "draining".
   *
   * We must memoise this function so that it has a stable identity across every
   * re-render otherwise it might trigger new runs of `useEffect` below.
   */
  const catchEvent = useCallback((evt: AnalyticsEvent) => {
    setEarlyEvents(others => [...others, evt]);
  }, []);

  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    /**
     * This runs basically on the App's first render which is usually way before
     * any vendor scripts have loaded. At this early stage, we'll just listen
     * for any events that fire until the vendor scripts have loaded, and keep
     * them safe for that time.
     */
    analyticsEvents.addEventListener(EVENT_NAME, catchEvent);

    /**
     * Setup listeners for our vendor scripts to know when we can stop collecting
     * the early events and start sending them directly to the vendor instead.
     */
    const { promise: gtmLoad, cancel: cancelGtmLoadWaiter } = makeWeakCancelable(waitForGtm());
    const { promise: adobeLoad, cancel: cancelAdobeLoadWaiter } = makeWeakCancelable(waitForAdobe());

    gtmLoad.then(onGtmLoaded);
    adobeLoad.then(onAdobeLoaded);

    /*
     * Cleanup on unmount
     */
    return (): void => {
      /*
       * Stop watching for the vendor script initialization
       * (note: this does not actually stop the vendor script loads)
       */
      cancelAdobeLoadWaiter();
      cancelGtmLoadWaiter();

      /*
       * Stop listening for early events
       */
      analyticsEvents.removeEventListener(EVENT_NAME, catchEvent);
    };
  }, [shouldRun, catchEvent, analyticsEvents]);

  /*
   * When all vendor scripts have loaded, send events directly
   * to them instead of catching and storing in `earlyEvents`
   */
  useEffect(() => {
    /*
     * If all our vendor scripts have loaded, we don't need to
     * catch the events anymore.
     *
     * Any further events are no longer early nor late:
     * they arrive precisely when they mean to.
     */
    if (hasAdobeLoaded && hasGtmLoaded) {
      analyticsEvents.removeEventListener(EVENT_NAME, catchEvent);
    }
  }, [hasAdobeLoaded, hasGtmLoaded, analyticsEvents, catchEvent]);

  /*
   * When Adobe has loaded, make it subscribe to events
   * (and force-send all the events that happened before
   * it was ready)
   */
  useEffect(() => {
    if (hasAdobeLoaded) {
      analyticsEvents.addEventListener(EVENT_NAME, sendToAdobe);

      if (!hasDrainedEarlyEventsToAdobe) {
        earlyEvents.forEach(sendToAdobe);
        setHasDrainedEarlyEventsToAdobe(true);
      }
    }

    /*
     * Unsubscribe on cleanup.
     */
    return (): void => {
      analyticsEvents.removeEventListener(EVENT_NAME, sendToAdobe);
    };
  }, [hasAdobeLoaded, analyticsEvents, earlyEvents, hasDrainedEarlyEventsToAdobe]);

  /*
   * When GTM has loaded, make it subscribe to events
   * (and force-send all the events that happened before
   * it was ready)
   */
  useEffect(() => {
    if (hasGtmLoaded) {
      analyticsEvents.addEventListener(EVENT_NAME, sendToGtm);

      if (!hasDrainedEarlyEventsToGtm) {
        earlyEvents.forEach(sendToGtm);
        setHasDrainedEarlyEventsToGtm(true);
      }
    }

    /*
     * Unsubscribe on cleanup
     */
    return (): void => {
      analyticsEvents.removeEventListener(EVENT_NAME, sendToGtm);
    };
  }, [hasGtmLoaded, analyticsEvents, earlyEvents, hasDrainedEarlyEventsToGtm]);

  /*
   * Expose the `analyticsEvents` EventTarget class to the global
   * namespace so any other external vendors can subscribe on their
   * own without us having to hook them up.
   *
   * @todo: make Adobe and Google do this instead of us managing it here.
   */
  useEffect(() => {
    (window as any).BELONG = (window as any).BELONG || {};
    (window as any).BELONG.analyticsEvents = analyticsEvents;

    return (): void => {
      delete (window as any).BELONG.analyticsEvents;
    };
  }, [analyticsEvents]);
}

export default useConnectToAnalyticsVendors;
