import { Entry } from 'contentful';
import { IArticleBlog, IPage } from '@belong/types';
import { FRichText, FImage, SectionParser } from '@belong/contentful';
import FSectionArticleGroup from '@belong/ui-sections/sections/sectionArticleGroup/model';

const FArticleBlog = async ({ fields }: Entry<any>, sectionParser: SectionParser): Promise<IArticleBlog> => {
  const sections = fields.sections || [];
  const sectionModels = sections.map(sectionParser);

  return {
    articleBody: fields.articleBody && FRichText(fields.articleBody),
    contentType: 'articleBlog',
    coverImage: FImage(fields.coverImage),
    heading: fields.heading,
    identifier: fields.identifier,
    relatedArticleGroup: fields.relatedArticleGroup && FSectionArticleGroup(fields.relatedArticleGroup),
    sections: await Promise.all(sectionModels),
    subheading: fields.subheading && FRichText(fields.subheading)
  };
};

export const isIArticleBlog = (data: IPage): boolean => {
  return !!data.blogArticle;
};

export const doesPageLinkToBlogArticle = (pageQueryResults: Array<Entry<any>>): boolean => {
  const data = pageQueryResults[0];

  if (!data) {
    return false;
  }

  const { blogArticle, page } = data.fields;

  if (blogArticle) {
    return true;
  }

  return page?.sys?.contentType?.sys?.id === 'articleBlog';
};

export default FArticleBlog;
